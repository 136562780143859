import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseAutoComplete from "../../../../../../shared/baseForm/BaseAutoComplete";
import BaseCheckBox from "../../../../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import {
  htiOrderFields,
  htiOrderFields2,
  htiOrderFields3,
  htiOrderFields5,
  shippingAddressFields,
} from "./forms.constants";
import { useForm } from "react-hook-form";
import { useGetAvailableSeasonSelectBoxQuery } from "../../../../../../../store/apis/SeasonApis";
import { useGetDietSelectBoxQuery } from "../../../../../../../store/apis/DietsApi";
import { debounce, uniqueId } from "lodash";
import { useGetVendorSelectBoxQuery } from "../../../../../../../store/apis/VendorApis";
import { useGetBillToTypesSelectListQuery } from "../../../../../../../store/apis/GenericApis";
import {
  useAddPackingSlipMutation,
  useGetPackingSlipToEditQuery,
  useUpdatePackingSlipMutation,
} from "../../../../../../../store/apis/PackingSlipAPIs";
import { useGetCorporateGroupHTISelectQuery } from "../../../../../../../store/apis/CorporateGroupApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetProductListQuery } from "../../../../../../../store/apis/ProductAPIs";
import { useGetFacilityByIdQuery } from "../../../../../../../store/apis/FacilityApis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import ValidationErrorSummary from "../../../../../../shared/validationErrorSummary/ValidationErrorSummary";
// add stylesheet
import "./AddCommunityHTIOrder.css";
import { BaseMultiSelect } from "../../../../../../shared/baseForm/BaseMultiSelect";
import BaseDatePicker from "components/shared/baseForm/BaseDatePicker";
import { REQUIRED_ERROR } from "utils/errorMessages";
import dayjs from "dayjs";

export default function AddCommunityHTIOrder() {
  // const [defaultDiets] = useState([]);

  const [searchParams] = useSearchParams();

  const facilityId = searchParams.get("id");
  const orderId = searchParams.get("orderId");
  const isEdit = !!orderId;
  const navigate = useNavigate();
  const [debouncedVendorSearch, setDebouncedVendorSearch] = useState("");
  const [defaultVendorOption, setDefaultVendorOption] = useState([]);

  const { data: billToTypes, isLoading: billToTypesLoading } =
    useGetBillToTypesSelectListQuery();

  const { data: htiUsers, isLoading: htiUsersLoading } =
    useGetCorporateGroupHTISelectQuery();

  const {
    data: htiOrder,
    isLoading: htiOrderLoading,
    isSuccess: htiOrderSuccess,
  } = useGetPackingSlipToEditQuery(orderId, { skip: !isEdit });

  const [
    addHTIOrder,
    {
      isError: isAddError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addError,
    },
  ] = useAddPackingSlipMutation();

  const [
    updateHTIOrder,
    {
      isError: isUpdateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateError,
    },
  ] = useUpdatePackingSlipMutation();

  const isError = isAddError || isUpdateError;
  const error = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;

  // const [defaultSeasons] = useState([]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
    watch,
  } = methods;
  const { data: vendors, isFetching: isVendorFetching } =
    useGetVendorSelectBoxQuery(debouncedVendorSearch, {
      refetchOnMountOrArgChange: true,
    });

  const {
    data: products,
    isFetching: isProductFetching,
    isSuccess: isProductSuccess,
  } = useGetProductListQuery();

  const handleVendorSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedVendorSearch(query);
        // setDefaultVendorOption([]);
      }, 400),
    []
  );

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (htiOrder && !htiOrderLoading && htiOrderSuccess) {
      reset(htiOrder);
      setDefaultVendorOption([
        {
          label: htiOrder.vendorName,
          value: htiOrder.vendorId,
        },
      ]);

      htiOrder.selectedProducts.map((product) => {
        setValue("product-" + product.id, true);
        setValue("productQuantity-" + product.id, product.quantity);
        setValue("productPrice-" + product.id, product.price);
      });
    }
  }, [htiOrder, htiOrderLoading, htiOrderSuccess]);

  useEffect(() => {
    if (products && isProductSuccess && !isProductFetching) {
      // const productList = products.map((product) => ({
      //   label: product.productName,
      //   value: product.productId,
      // }));
      // setDefaultVendorOption([]);

      if (!isEdit && currentFacility) {
        setValue("webBasedMenuPrice", currentFacility.menuCharge);
        setValue("productPrice-7", currentFacility.tableSideCharge);
        setValue("productPrice-4", currentFacility.mealCardCharge);
        setValue("productPrice-10", currentFacility.customizationCharge);
      }

      if (!isEdit) {
        setValue("dateOrdered", dayjs(new Date()).format("YYYY-MM-DD"));
      }
    }
  }, [isProductFetching, isProductSuccess, products]);

  const selectedProducts = watch(
    products && isProductSuccess && !isProductFetching
      ? products.map((product) => "product-" + product.id)
      : ""
  );
  const productQuantities = watch(
    products && isProductSuccess && !isProductFetching
      ? products.map((product) => "productQuantity-" + product.id)
      : ""
  );
  const productPrices = watch(
    products && isProductSuccess && !isProductFetching
      ? products.map((product) => "productPrice-" + product.id)
      : ""
  );

  const { data: seasons, isFetching: isFetchingSeasons } =
    useGetAvailableSeasonSelectBoxQuery({ take: 2, skip: 0 });

  const { data: diets, isFetching: isFetchingDiets } = useGetDietSelectBoxQuery(
    " ",
    { refetchOnMountOrArgChange: true }
  );

  const isPrintedCopy = watch("isHardCopy");
  const printedQuantity = watch("hardCopyMenuQuantity");
  const printedPrice = watch("hardCopyMenuPrice");

  const isWebBased = watch("isWebBasedMenu");
  const webBasedMenuQuantity = watch("webBasedMenuQuantity");
  const webBasedMenuPrice = watch("webBasedMenuPrice");

  const additionalCharges = watch("additionalCharges");
  const implementationFee = watch("implementationFee");
  const shipping = watch("shipping");

  useEffect(() => {
    let pricing = 0;
    let total = 0;
    if (isPrintedCopy) {
      pricing += printedQuantity * printedPrice;
    }
    if (isWebBased) {
      pricing += webBasedMenuQuantity * webBasedMenuPrice;
    }

    products?.map((product, index) => {
      if (selectedProducts[index]) {
        pricing += productQuantities[index] * productPrices[index];
      }
    });

    total = parseFloat(pricing) || 0;

    total += parseFloat(additionalCharges) || 0;
    total += parseFloat(implementationFee) || 0;
    total += parseFloat(shipping) || 0;

    setValue("pricing", pricing.toFixed(2));
    setValue("total", total.toFixed(2));

    // if (!isEdit && currentFacility) {
    //   setValue("webBasedMenuPrice", currentFacility.menuCharge);
    //   setValue("productPrice-7", currentFacility.tableSideCharge);
    //   setValue("productPrice-4", currentFacility.mealCardCharge);
    // }
  }, [
    isPrintedCopy,
    printedQuantity,
    printedPrice,
    isWebBased,
    webBasedMenuQuantity,
    webBasedMenuPrice,
    additionalCharges,
    shipping,
    selectedProducts,
    productPrices,
    productQuantities,
  ]);

  const { data: currentFacility } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId,
  });

  const shipToFacility = watch("shipToFacilityAddress");

  useEffect(() => {
    if (shipToFacility) {
      if (currentFacility) {
        const { phoneNumber, address, city, state, zip } = currentFacility;
        setValue("shipToAddress", address);
        setValue("shipToCity", city);
        setValue("shipToState", state);
        setValue("shipToZip", zip);
        setValue("phoneNumber", phoneNumber);
      }
    }
  }, [shipToFacility]);

  const onSubmit = (data) => {
    data.selectedProducts = [];
    data.facilityId = facilityId;

    products.map((product) => {
      return data.selectedProducts.push({
        id: product.id,
        price: data["productPrice-" + product.id] || 0,
        quantity: data["productQuantity-" + product.id] || 0,
        isSelected: data["product-" + product.id] || false,
      });
    });

    if (isEdit) {
      data.id = orderId;
      updateHTIOrder(data);
      return;
    }
    addHTIOrder(data);
  };

  const isEditLoading = isEdit && htiOrderLoading;

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <Grid container spacing={2}>
            <GeneratedForm
              list={shippingAddressFields}
              control={control}
              errors={errors}
              disabled={!!shipToFacility}
            />
            <GeneratedForm
              list={htiOrderFields}
              control={control}
              errors={errors}
            />
            <Grid item md={6}>
              <BaseMultiSelect
                name="seasonIds"
                id="seasonIds"
                label="Season"
                control={control}
                setValue={setValue}
                options={seasons || []}
                loading={isFetchingSeasons}
                // defaultOptions={defaultSeasons}
                // validationProps={{
                //   required: "Season field is required.",
                // }}
                errors={errors}
              />
            </Grid>
            <Grid item md={6} />
            <Grid item md={10}>
              <BaseMultiSelect
                label="Diets"
                name="dietIds"
                id="dietIds"
                setValue={setValue}
                control={control}
                options={diets || []}
                errors={errors}
                // validationProps={{
                //   required: "Diets field is required.",
                // }}
                loading={isFetchingDiets}
                // defaultOptions={defaultDiets}
              />
            </Grid>
            <Grid item md={2} />
            <GeneratedForm
              list={htiOrderFields2}
              control={control}
              errors={errors}
            />
            <GeneratedForm
              list={htiOrderFields3}
              control={control}
              errors={errors}
            />
            <Grid item md={8}>
              <Grid
                container
                spacing={1}
                sx={{
                  border: "1px solid",
                  paddingBottom: "8px",
                  paddingRight: "8px",
                  borderRadius: "10px",
                  margin: "8px",
                  borderColor: "black",
                }}>
                {products &&
                  !isProductFetching &&
                  isProductSuccess &&
                  products.map((product) => {
                    return (
                      <React.Fragment key={`PGrid-${product?.id}`}>
                        <Grid md={6} item>
                          <BaseCheckBox
                            control={control}
                            name={`product-${product?.id}`}
                            label={product.name}
                            id={`product-${product?.id}`}
                            value={product?.id}
                            validationProps={{}}
                            errors={errors}
                          />
                        </Grid>
                        <Grid md={3} item>
                          <BaseInput
                            control={control}
                            name={`productPrice-${product?.id}`}
                            id={`productPrice-${product?.id}`}
                            label={"Price"}
                            validationProps={{}}
                            type="number"
                            unit="$"
                            unitPosition="start"
                            min={0}
                            errors={errors}
                          />
                        </Grid>

                        <Grid md={3} item>
                          <BaseInput
                            control={control}
                            name={"productQuantity-" + product?.id?.toString()}
                            id={"productQuantity-" + product?.id?.toString()}
                            label={"Quantity"}
                            validationProps={{}}
                            type="number"
                            min={0}
                            max={1000}
                            errors={errors}
                          />
                        </Grid>
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item md={4} />
            <Grid item md={6}>
              <BaseAutoComplete
                formSelectProps={{
                  props: {
                    name: "vendorId",
                    id: "vendorId",
                    label: "Vendor",
                  },
                  validationProps: { required: REQUIRED_ERROR("Vendor") },
                }}
                control={control}
                errors={errors}
                options={vendors || []}
                loading={isVendorFetching}
                defaultOptions={defaultVendorOption}
                // defaultOptions={[]}
                handleSearch={handleVendorSearch}
              />
            </Grid>
            <Grid item md={6}></Grid>
            <Grid item md={6}>
              <BaseInput
                control={control}
                name="billToName"
                id="billToName"
                label="Bill To Name"
                errors={errors}
              />
            </Grid>
            <Grid item md={6} />
            <Grid item xs={6}>
              <BaseSelect
                name="billToTypeId"
                id="billToTypeId"
                label="Bill To Type"
                control={control}
                errors={errors}
                loading={billToTypesLoading}
                options={billToTypes || []}
                validationProps={{ required: REQUIRED_ERROR("Bill to type") }}
              />
            </Grid>
            <Grid item md={6} />
            <Grid item xs={6}>
              <BaseSelect
                name="soldByUserId"
                id="soldByUserId"
                label="Sold By"
                control={control}
                errors={errors}
                loading={htiUsersLoading}
                options={htiUsers || []}
              />
            </Grid>
            <Grid item md={6} />
            <Grid item xs={6}>
              <BaseSelect
                name="referredByUserId"
                id="referredByUserId"
                label="Referred By"
                control={control}
                errors={errors}
                loading={htiUsersLoading}
                options={htiUsers || []}
              />
            </Grid>
            <Grid item md={6} />
            <GeneratedForm
              list={htiOrderFields5}
              control={control}
              errors={errors}
            />
            <Grid item md={12}>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <BaseSubmitButton
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                />
              </Box>
            </Grid>
            <Grid item md={12}>
              {isError ? (
                <Typography
                  sx={{
                    color: "error",
                  }}>
                  {error?.data?.messages}
                </Typography>
              ) : undefined}

              <ValidationErrorSummary errors={errors} />
            </Grid>
          </Grid>
        </>
      )}{" "}
    </>
  );
}

function GeneratedForm({ list, control, errors, ...rest }) {
  {
    return list.map((data) => {
      const props = data?.props;
      return (
        <Grid key={`Grid-${props?.name || uniqueId()}`} item md={data?.size}>
          {data?.type === "select" ? (
            <BaseSelect
              control={control}
              {...props}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}
              options={data?.options}
              {...rest}
            />
          ) : data?.type === "checkbox" ? (
            <BaseCheckBox
              control={control}
              {...props}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}
            />
          ) : data?.type === "offset" ? (
            <></>
          ) : data?.type === "date" ? (
            <BaseDatePicker
              control={control}
              {...props}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}
              {...rest}
            />
          ) : (
            <BaseInput
              control={control}
              {...props}
              name={props?.name}
              validationProps={data?.validationProps}
              errors={errors}
              {...rest}
            />
          )}
        </Grid>
      );
    });
  }
}
